import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
//
import { useCookies } from 'react-cookie';
import endpoints from 'src/api/endpoints';
import { AuthContext } from './auth-context';
import axiosInstance from 'src/utils/axios-instanct';
import { isValidToken, removeAllCookie, setLocalCookie } from './utils';
import { ActionMapType, AuthStateType, AuthUserType } from '../../types';
import { useRouter } from 'src/routes/hook';
import { __DEV__ } from 'src/utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: AuthStateType;
  [Types.LOGIN]: AuthStateType;
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  balance: null,
  loading: true,
  role: null,
  permissions: [],
  isAdmin: false,
  isAgent: false,
  isSenior: false,
  isSpecialUser: false,
  isRoleDataEntry: false,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
      role: action.payload.role,
      balance: action.payload.balance,
      permissions: action.payload.permissions,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      balance: action.payload.balance,
      user: action.payload.user,
      role: action.payload.role,
      permissions: action.payload.permissions,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      balance: null,
      user: null,
      role: null,
      permissions: [],
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const router = useRouter();

  const [cookies] = useCookies();
  const [state, dispatch] = useReducer(reducer, initialState);

  const csrfToken = useCallback(async () => {
    await axiosInstance.get(endpoints.csrf);
  }, []);

  const getMe = async () => {
    const res = await axiosInstance.get(endpoints.auth.me);
    const resRolePermissions = await axiosInstance.get(endpoints.auth.permissions);

    const { user, balance } = res.data;
    const { role, permissions } = resRolePermissions?.data;

    dispatch({
      type: Types.INITIAL,
      payload: {
        user,
        role,
        balance,
        permissions,
      },
    });
  };

  const initialize = useCallback(async (_accessToken = null) => {
    try {
      // const accessToken = sessionStorage.getItem(STORAGE_KEY);
      const accessToken = _accessToken || cookies['access-token'];
      if (accessToken && isValidToken(accessToken)) {
        // setSession(accessToken);
        setLocalCookie(accessToken, 31622400);
        getMe();
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
            role: null,
            balance: null,
            permissions: [],
          },
        });
      }
    } catch (error) {
      removeAllCookie();
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
          role: null,
          balance: null,
          permissions: [],
        },
      });
    }
  }, []);

  useEffect(() => {
    csrfToken();
    initialize();
  }, [initialize, csrfToken]);

  // LOGIN
  const login = useCallback(async (username: string, password: string) => {
    const data = {
      username,
      password,
    };

    const res = await axiosInstance.post(endpoints.auth.login, data);

    const { access_token: accessToken, expires_in: expiresIn } = res.data;
    // setSession(accessToken);
    setLocalCookie(accessToken, expiresIn);

    initialize(accessToken);
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    // setSession(null);
    removeAllCookie();
    dispatch({
      type: Types.LOGOUT,
    });
    if (__DEV__) {
      window.location.href = '/auth/login';
    } else {
      window.location.href = 'https://khmer5d.com/auth/login';
    }
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;
  const isAdmin = state?.role === 'admin' || state?.role === 'super_admin';
  const isSenior = state?.role === 'senior';
  const isSuperSenior = state?.role === 'super_senior';
  const isAgent = state?.role === 'agent';
  const isRoleDataEntry = Boolean(state.user?.id == 2436);
  const isSpecialUser = state?.user?.id === 1278 || state?.user?.id === 1247;
  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      role: state.role,
      balance: state.balance,
      permissions: state.permissions,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      isAdmin,
      isAgent,
      isSenior,
      isSuperSenior,
      isSpecialUser,
      isRoleDataEntry,
      //
      login,
      logout,
      getMe,
      csrfToken,
    }),
    [
      login,
      logout,
      getMe,
      csrfToken,
      state.user,
      state.role,
      state.balance,
      state.permissions,
      status,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
