import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { IRouteObject } from '.';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const UserAdminPage = lazy(() => import('src/pages/dashboard/user-new/admin'));
const UserAgentPage = lazy(() => import('src/pages/dashboard/user-new/agent'));
const UserSubAgentPage = lazy(() => import('src/pages/dashboard/user-new/sub-agent'));
const UserDealerPage = lazy(() => import('src/pages/dashboard/user-new/dealer'));
const ChangePassword = lazy(() => import('src/pages/dashboard/user-new/change-password'));
const UserPermissionPage = lazy(() => import('src/pages/dashboard/user-permission/permission'));
const UserQrcodePage = lazy(() => import('src/pages/dashboard/user/qrcode'));

//AGENT
const NewAgentPage = lazy(() => import('src/pages/dashboard/agent/agent-tab'));

//MANAGE
const GuessResultPage = lazy(() => import('src/pages/dashboard/manage/guess-result'));
const MinMaxTurnoverPage = lazy(() => import('src/pages/dashboard/manage/turnover'));
const UpdateUserPage = lazy(() => import('src/pages/dashboard/manage/update-user'));
const TicketResultTimePage = lazy(() => import('src/pages/dashboard/manage/ticket-time'));
const TicketTransferPage = lazy(() => import('src/pages/dashboard/manage/ticket-transfer'));
const ReportTransferLogPage = lazy(() => import('src/pages/dashboard/report/transfer-log-report'));

// SETTING
const UserZonePage = lazy(() => import('src/pages/dashboard/setting/user-zone'));
const ResultTimePage = lazy(() => import('src/pages/dashboard/setting/result-time'));

// TICKET
const TicketEditPage = lazy(() => import('src/pages/dashboard/ticket/ticket-edit'));
const TicketCreatePage = lazy(() => import('src/pages/dashboard/ticket/ticket-create'));
const TicketBreakNumberPage = lazy(() => import('src/pages/dashboard/ticket/ticket-break-number'));

// REPORT
const ReportDailyDetailPage = lazy(() => import('src/pages/dashboard/report/daily-detail'));
const ReportWinLosePage = lazy(() => import('src/pages/dashboard/report/win-lose'));
const ReportBetDetailPage = lazy(() => import('src/pages/dashboard/report/bet-detail'));
const ReportBetOverLimitPage = lazy(() => import('src/pages/dashboard/report/bet-over-limit'));
const ReportDailyAgentPage = lazy(() => import('src/pages/dashboard/report/daily-user'));
const ReportDailyAgentProfit = lazy(() => import('src/pages/dashboard/report/agent-profit'));
const ReportDailyDealerPage = lazy(() => import('src/pages/dashboard/report/daily-dealer'));
const ReportDailyAgentDetailPage = lazy(
  () => import('src/pages/dashboard/report/daily-user-detail')
);
const ReportTopBetPage = lazy(() => import('src/pages/dashboard/report/top-bet-report'));

// NER VERSION OF REPORT V2
const AgentReportPage = lazy(() => import('src/pages/dashboard/report/agent-report'));

const DealerReportV2 = lazy(() => import('src/pages/dashboard/reportV2/dealer-report'));
const ReportDailyAgentPageV2 = lazy(() => import('src/pages/dashboard/reportV2/daily-user-report'));
const ReportDailyAgentDetailPageV2 = lazy(
  () => import('src/pages/dashboard/reportV2/daily-user-detail')
);
const ReportDailyCashPage = lazy(() => import('src/pages/dashboard/reportV2/daily-cash-report '));

const ReportDailyTotalPage = lazy(() => import('src/pages/dashboard/reportV2/daily-total'));
const ReportDailyTicketPage = lazy(() => import('src/pages/dashboard/report/daily-ticket-list'));

const ReportRegisterMoneyPage = lazy(() => import('src/pages/dashboard/reportV2/register-money'));
const ReportJointRegisterMoneyPage = lazy(
  () => import('src/pages/dashboard/reportV2/joint-register-money')
);
const ReportDailyJointPage = lazy(() => import('src/pages/dashboard/reportV2/daily-joint'));

//RESULT
const ReleaseResultPage = lazy(() => import('src/pages/dashboard/result/release-result'));
const GenerateResultPage = lazy(() => import('src/pages/dashboard/result/generate-result'));

// ----------------------------------------------------------------------

export const dashboardRoutes: IRouteObject[] = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      // { path: 'ecommerce', element: <OverviewEcommercePage /> },
      // { path: 'analytics', element: <OverviewAnalyticsPage /> },
      // { path: 'banking', element: <OverviewBankingPage /> },
      // { path: 'booking', element: <OverviewBookingPage /> },
      // { path: 'file', element: <OverviewFilePage /> },
      {
        path: 'ticket',
        children: [
          { element: <TicketCreatePage />, index: true },
          { path: 'edit', element: <TicketEditPage /> },
          {
            path: 'break',
            element: <TicketBreakNumberPage />,
            permissions: ['break_number_ticket_view'],
          },

          // { path: 'lotto-5d', element: <Ticket5dCreatePage /> },
          // { path: 'edit/lotto-5d', element: <Ticket5dEditPage /> },
          // { path: 'edit/lotto-vn', element: <TicketVnEditPage /> },
        ],
      },
      {
        path: 'setting',
        children: [
          { element: <ResultTimePage />, index: true, permissions: ['result_time_setting_view'] },

          { path: 'zone', element: <UserZonePage />, permissions: ['user_zone_setting_view'] },
        ],
      },
      {
        path: 'manage',
        children: [
          { path: 'turnover', element: <MinMaxTurnoverPage /> },
          {
            path: 'guess-result',
            element: <GuessResultPage />,
          },
          {
            path: 'user',
            element: <UpdateUserPage />,
          },
          {
            path: 'ticket',
            element: <TicketResultTimePage />,
          },
          {
            path: 'transfer/ticket',
            element: <TicketTransferPage />,
          },
        ],
      },

      {
        path: 'user',
        children: [
          { element: <UserAdminPage />, index: true, permissions: ['admin_user_view'] },
          {
            path: 'agent/setting/:id',
            element: <NewAgentPage />,
            permissions: ['agent_user_view'],
          },
          { path: 'agent', element: <UserAgentPage />, permissions: ['agent_user_view'] },
          { path: 'agent/sub', element: <UserSubAgentPage />, permissions: ['agent_user_view'] },
          {
            element: <UserAgentPage />,
            path: 'agent/:id',
            permissions: ['agent_user_view'],
          },
          { path: 'dealer', element: <UserDealerPage />, permissions: ['dealer_user_view'] },
          {
            path: 'permission',
            element: <UserPermissionPage />,
            permissions: ['group_permission_user_view'],
          },
          { path: 'password', element: <ChangePassword /> },
          { path: 'qrcode/:id?', element: <UserQrcodePage /> },
          // { path: 'cards', element: <UserCardsPage /> },
          // { path: 'list', element: <UserListPage /> },
          // { path: 'new', element: <UserCreatePage /> },
          // { path: ':id/edit', element: <UserEditPage /> },
          // { path: 'account', element: <UserAccountPage /> },
        ],
      },
      {
        path: 'result',
        children: [
          { path: ':id?', element: <ReleaseResultPage />, index: true },
          { path: 'generate', element: <GenerateResultPage /> },
        ],
      },
      {
        path: 'report',
        children: [
          {
            element: <ReportDailyDetailPage />,
            index: true,
            permissions: ['daily_detail_report_view'],
          },
          { element: <ReportWinLosePage />, path: 'winlose' },
          { element: <ReportBetDetailPage />, path: 'bet', permissions: ['daily_report_view'] },
          {
            element: <ReportBetOverLimitPage />,
            path: 'over-limit',
            permissions: ['break_number_ticket_view'],
          },
          {
            element: <ReportDailyAgentPage />,
            path: 'user/:id?',
            permissions: ['daily_report_view'],
          },
          {
            element: <ReportDailyAgentDetailPage />,
            path: 'user/detail/:id',
            permissions: ['daily_report_view'],
          },
          // {
          //   element: <ReportDailyTicketDetailPage />,
          //   path: 'ticket',
          //   permissions: ['daily_report_view'],
          // },
          {
            element: <ReportDailyTicketPage />,
            path: 'ticket/:id?',
          },
          {
            element: <DealerReportV2 />,
            path: 'dealer',
            permissions: ['break_number_ticket_view'],
          },
          {
            element: <ReportDailyCashPage />,
            path: 'cash/:id?',
            permissions: ['agent_user_view'],
          },
          {
            element: <AgentReportPage />,
            path: 'agent/:id?',
          },
          {
            element: <ReportDailyAgentProfit />,
            path: 'agent/profit/:id?',
          },
          {
            element: <ReportDailyAgentPageV2 />,
            path: 'v2/user/:id?',
            permissions: ['agent_user_view'],
          },
          {
            element: <ReportDailyAgentDetailPageV2 />,
            path: 'v2/user/detail/:id?',
            permissions: ['daily_report_view'],
          },
          {
            element: <ReportTopBetPage />,
            path: 'top-bet',
          },
          {
            element: <ReportTransferLogPage />,
            path: 'transfer/log/:id?',
          },

          // {
          //   element: <ReportDailyAgentDetailPageV2 />,
          //   path: 'user/detail/:id?',
          //   permissions: ['daily_report_view'],
          // },
        ],
      },
      {
        path: 'reportv2',
        children: [
          // {
          //   element: <ReportDailyAgentPageV2 />,
          //   path: 'user/v2/:id?',
          //   permissions: ['agent_user_view'],
          // },
          // {
          //   element: <ReportDailyAgentDetailPageV2 />,
          //   path: 'user/detail/:id?',
          //   permissions: ['daily_report_view'],
          // },
          //sample
          {
            element: <ReportDailyJointPage />,
            path: 'joint',
          },
          {
            element: <ReportJointRegisterMoneyPage />,
            path: 'joint-register/money',
          },
          {
            element: <ReportRegisterMoneyPage />,
            path: 'register/money',
          },
          {
            element: <ReportDailyTicketPage />,
            path: 'ticket/bet',
          },
          {
            element: <ReportDailyTotalPage />,
            path: 'ticket/total',
          },
        ],
      },
    ],
  },
];
